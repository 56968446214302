import configuration from "../assets/menuImages/Configuración.png";
import buys from "../assets/menuImages/shopping-basket.png";
import security from "../assets/menuImages/apoyo-tecnico.png";
import instalation from "../assets/menuImages/Instalaciones.png";
import incidents from "../assets/menuImages/Incidencias.png";
import shopCar from "../assets/menuImages/Carritos.png";
import satisfactions from "../assets/menuImages/Satisfacciones.png";
import users from "../assets/menuImages/Usuario.png";
import equipment from "../assets/menuImages/Equipos.png";
import projects from "../assets/menuImages/Proyectos.png";
import ganttDiagram from "../assets/menuImages/diagrama-de-flujo.png";
import singUp from "../assets/menuImages/datafono.png";
import business from "../assets/menuImages/business-man.png";
import invoice from "../assets/menuImages/invoice.png";
import deliveryNote from "../assets/menuImages/albaran.png";
import calendario from "../assets/menuImages/calendario.png";
import beneficios from "../assets/menuImages/beneficios.png";
import download from "../assets/menuImages/download.png";
import camionReparto from "../assets/menuImages/camion-de-reparto.png";
import buzon from "../assets/menuImages/buzon.png";
import gastos from "../assets/menuImages/gastos.png";
import tags from "../assets/menuImages/tags.png";
import reasons from "../assets/menuImages/lightbulb.png";
import permiso from "../assets/menuImages/permiso.png";
import conexion from "../assets/menuImages/conexion-a-la-nube.png";
import vacaciones from "../assets/menuImages/vacaciones-de-verano.png";
import equipo from "../assets/menuImages/equipo.png";
import cambio from "../assets/menuImages/cambio.png";
import clock from "../assets/menuImages/clock.png";
import productos from "../assets/menuImages/productos.png";
import map from "../assets/menuImages/mapa.png";
import firmar from "../assets/menuImages/firmar.png";
import produccion from "../assets/menuImages/produccion.png";
import construccion from "../assets/menuImages/construccion.png";
import casco from "../assets/menuImages/casco.png";

export const PRINCIPALMENU = [
  {
    keyApp: "configuration",
    navigation: "configuration",
    title: "Configuración",
    subtitle: "Usuarios, roles",
    image: `url(${configuration})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "instalationsMenu",
    navigation: "instalations",
    title: "Instalación",
    subtitle: "Instalaciones y equipos",
    image: `url(${instalation})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "incidencesMenu",
    navigation: "incidences",
    title: "Incidencias",
    subtitle: "Tus incidencias y partes de trabajo",
    image: `url(${incidents})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "shopCarts",
    navigation: "shopCarts",
    title: "Compras",
    subtitle: "Carritos y Comprar",
    image: `url(${buys})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "satisfactions",
    navigation: "satisfactions",
    title: "Satisfacciones",
    subtitle: "Satisfacciones sobre las incidencias",
    image: `url(${satisfactions})`,
    showInMenu: true,
  },
  {
    keyApp: "stadistics",
    navigation: "stadistics",
    title: "Estadísticas",
    subtitle: "Estadísticas sobre la aplicación",
    image: `url(${beneficios})`,
    showInMenu: true,
  },
  {
    keyApp: "projectsMenu",
    navigation: "projects",
    title: "Proyectos",
    subtitle: "Proyectos y diagrama de gantt",
    image: `url(${projects})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "singUp",
    navigation: "singUp",
    title: "Fichar",
    subtitle: "Fichar entradas y salidas",
    image: `url(${singUp})`,
    showInMenu: true,
  },
  {
    keyApp: "singUpsMenu",
    navigation: "singUps",
    title: "Control de accesos",
    subtitle: "Ver todos los accesos",
    image: `url(${business})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "RRHH",
    navigation: "RRHH",
    title: "RRHH",
    subtitle: "Recursos humanos",
    image: `url(${equipo})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "dosification",
    navigation: "dosification",
    title: "Dosificación",
    subtitle: "Productos y dosificaciones",
    image: `url(${productos})`,
    showInMenu: true,
    hasChildren: true,
  },
  {
    keyApp: "invoicesDeliveryNotes",
    navigation: "allInvoicesDeliveryNotes",
    title: "Facturas y albaranes",
    subtitle: "Descargar las facturas y albaranes de este año",
    image: `url(${invoice})`,
    hasChildren: true,
    showInMenu: true,
  },
  {
    keyApp: "episMenu",
    navigation: "epis",
    title: "EPIS y materias primas",
    subtitle: "Control de EPIS y materias primas",
    image: `url(${casco})`,
    showInMenu: true,
    hasChildren: true,
  },
  {
    keyApp: "repartiments",
    navigation: "repartiments",
    title: "Repartos",
    subtitle: "Ver todos los albaranes de hoy",
    image: `url(${camionReparto})`,
    showInMenu: true,
  },
  {
    keyApp: "suggestion",
    navigation: "suggestion",
    title: "Sugerencias",
    subtitle: "Introduce errores o sugerencias",
    image: `url(${buzon})`,
    showInMenu: true,
  },
  {
    keyApp: "expenses",
    navigation: "expenses",
    title: "Gastos",
    subtitle: "Introduce gastos",
    image: `url(${gastos})`,
    showInMenu: true,
  },
  {
    keyApp: "opcua",
    navigation: "opcua",
    title: "OPCUA",
    subtitle: "Introduce la configuración del OPCUA",
    image: `url(${conexion})`,
    showInMenu: true,
  },
];

export const CONFIGURATIONMENU = [
  {
    keyApp: "users",
    navigation: "configuration/users",
    title: "Usuarios",
    subtitle: "Todos los usuarios",
    image: `url(${users})`,
    showInMenu: true,
  },
  {
    keyApp: "downloadDocuments",
    navigation: "configuration/downloadDocument",
    title: "Descargas de doc.",
    subtitle: "Ver todas las descargas de documentos",
    image: `url(${download})`,
    showInMenu: true,
  },
  {
    keyApp: "tags",
    navigation: "configuration/tags",
    title: "Tags",
    subtitle: "Ver todas las tags de gastos",
    image: `url(${tags})`,
    showInMenu: true,
  },
  {
    keyApp: "reasons",
    navigation: "configuration/reasons",
    title: "Motivos",
    subtitle: "Ver todos los motivos de horas extras",
    image: `url(${reasons})`,
    showInMenu: true,
  },
  {
    keyApp: "roles",
    navigation: "configuration/roles",
    title: "Roles",
    subtitle: "Ver todos los roles",
    image: `url(${permiso})`,
    showInMenu: true,
  },
];

export const PROJECTSMENU = [
  {
    keyApp: "projects",
    navigation: "projects/projects",
    title: "Proyectos",
    subtitle: "Todos los proyectos",
    image: `url(${projects})`,
    showInMenu: true,
  },
  {
    keyApp: "ganttDiagram",
    navigation: "projects/ganttDiagram",
    title: "Diagrama de gantt",
    subtitle: "Vista del diagrama de gantt",
    image: `url(${ganttDiagram})`,
    showInMenu: true,
  },
];

export const SHOPMENU = [
  {
    keyApp: "shopCarts",
    navigation: "shopCarts/carts",
    title: "Carritos",
    subtitle: "Todos los carritos",
    image: `url(${shopCar})`,
    showInMenu: true,
  },
  {
    keyApp: "buy",
    navigation: "shopCarts/buy",
    title: "Comprar",
    subtitle: "Realiza compras",
    image: `url(${buys})`,
    showInMenu: true,
  },
  {
    keyApp: "securitySheets",
    navigation: "shopCarts/security",
    title: "Fichas técnicas",
    subtitle: "Ver todas las fichas técnicas descargadas",
    image: `url(${security})`,
    showInMenu: true,
  },
];

export const INSTALATIONMENU = [
  {
    keyApp: "instalations",
    navigation: "instalations/instalation",
    title: "Instalaciones",
    subtitle: "Tus instalaciones",
    image: `url(${instalation})`,
    showInMenu: true,
  },
  {
    keyApp: "equipments",
    navigation: "instalations/equipment",
    title: "Equipos",
    subtitle: "Tus equipos",
    image: `url(${equipment})`,
    showInMenu: true,
  },
];

export const INCIDENCESMENU = [
  {
    keyApp: "incidences",
    navigation: "incidences/incidences",
    title: "Incidencias",
    subtitle: "Tus incidencias",
    image: `url(${incidents})`,
    showInMenu: true,
  },
  {
    keyApp: "workOrders",
    navigation: "incidences/workOrders",
    title: "Partes de trabajo",
    subtitle: "Tus partes de trabajo",
    image: `url(${firmar})`,
    showInMenu: true,
  },
];

export const INVOICESMENU = [
  {
    keyApp: "invoices",
    navigation: "allInvoicesDeliveryNotes/invoices",
    title: "Facturas",
    subtitle: "Tus facturas",
    image: `url(${invoice})`,
    showInMenu: true,
  },
  {
    keyApp: "deliveryNotes",
    navigation: "allInvoicesDeliveryNotes/deliveryNotes",
    title: "Albaranes",
    subtitle: "Tus albaranes",
    image: `url(${deliveryNote})`,
    showInMenu: true,
  },
];

export const SINGUPS = [
  {
    keyApp: "singUps",
    navigation: "singUps/singUpsList",
    title: "Control de accesos",
    subtitle: "Ver todos los accesos",
    image: `url(${business})`,
    showInMenu: true,
  },
  {
    keyApp: "timeRequests",
    navigation: "singUps/timeRequest",
    title: "Peticiones",
    subtitle: "Peticiones de cambio de fecha",
    image: `url(${deliveryNote})`,
    showInMenu: true,
  },
  {
    keyApp: "map",
    navigation: "singUps/map",
    title: "Mapa",
    subtitle: "Mapa con los fichajes",
    image: `url(${map})`,
    showInMenu: true,
  },
];

export const RRHH = [
  {
    keyApp: "mySingUp",
    navigation: "RRHH/mySingUp",
    title: "Mis accesos",
    subtitle: "Consultar mis salidas y entradas",
    image: `url(${calendario})`,
    showInMenu: true,
  },
  {
    keyApp: "holidayRequests",
    navigation: "RRHH/holidayRequest",
    title: "Vacaciones",
    subtitle: "Peticiones de vacaciones y bajas",
    image: `url(${vacaciones})`,
    showInMenu: true,
  },
  {
    keyApp: "workShifts",
    navigation: "RRHH/workShift",
    title: "Turnos",
    subtitle: "Turnos de trabajo",
    image: `url(${cambio})`,
    showInMenu: true,
  },
  {
    keyApp: "leaves",
    navigation: "RRHH/leave",
    title: "Bajas",
    subtitle: "Bajas de trabajo",
    image: `url(${clock})`,
    showInMenu: true,
  },
];

export const DOSIFICATION = [
  {
    keyApp: "dosification",
    navigation: "dosification/dosification",
    title: "Dosificación",
    subtitle: "Productos y configuración",
    image: `url(${productos})`,
    showInMenu: true,
  },
  {
    keyApp: "dosificationProducts",
    navigation: "dosification/dosificationProducts",
    title: "Productos",
    subtitle: "Dosificaciones",
    image: `url(${produccion})`,
    showInMenu: true,
  },
  {
    keyApp: "stock",
    navigation: "dosification/stocks",
    title: "Stock",
    subtitle: "Stock de los productos",
    image: `url(${productos})`,
    showInMenu: true,
  },
  {
    keyApp: "dosificationConfig",
    navigation: "dosification/dosificationConfigs",
    title: "Configuración",
    subtitle: "Configuracion de los productos",
    image: `url(${productos})`,
    showInMenu: true,
  },
  {
    keyApp: "dosificationStadistics",
    navigation: "dosification/dosificationStadistics",
    title: "Estadísticas",
    subtitle: "Estadísticas de los productos",
    image: `url(${productos})`,
    showInMenu: true,
  },
];

export const EPIS = [
  {
    keyApp: "epis",
    navigation: "epis/epis",
    title: "Control de EPIS",
    subtitle: "Control de EPIS",
    image: `url(${casco})`,
    showInMenu: true,
  },
  {
    keyApp: "raws",
    navigation: "epis/raw",
    title: "Materias primas",
    subtitle: "Materias primas",
    image: `url(${construccion})`,
    showInMenu: true,
  },
];

export const BASICMENU = {
  configuration: CONFIGURATIONMENU,
  shopCarts: SHOPMENU,
  instalationsMenu: INSTALATIONMENU,
  projectsMenu: PROJECTSMENU,
  singUpsMenu: SINGUPS,
  RRHH,
  invoicesDeliveryNotes: INVOICESMENU,
  incidencesMenu: INCIDENCESMENU,
  dosification: DOSIFICATION,
  episMenu: EPIS
}

export const ALLMENU = Array.prototype.concat(
  PRINCIPALMENU,
  CONFIGURATIONMENU,
  SHOPMENU,
  INSTALATIONMENU,
  PROJECTSMENU,
  SINGUPS,
  RRHH,
  INVOICESMENU,
  INCIDENCESMENU,
  EPIS,
  DOSIFICATION
);
